import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header'
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
import Footer from '../Common/Dashboard/Footer';
import { userActions } from '../../actions';
import { apiConstants } from '../../constants';

function NextInLine() {
    const dispatch = useDispatch();

    const { language, nextInLineUsers, nextInLineClassicUsers } = useSelector(
        state => ({
            nextInLineUsers: state.nextInLineUsers,
            nextInLineClassicUsers: state.nextInLineClassicUsers,
            language: state.language,
        })
    );

    const nextInLineData = nextInLineUsers?.data?.data?.data;
    const classicUsers = nextInLineClassicUsers?.data?.data?.data;

    const [planType, setPlanType] = useState('');
    const [siteConstants, setSiteConstants] = useState([]);
    const [number, setNumber] = useState('1');
    const [isClassic, setIsClassic] = useState(false); // track if classicUsers should be displayed

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    const planHandler = (e) => {
        setPlanType(e.details);
    }

    const getList = (e) => {
        e.preventDefault();
        const obj = {
            userCurrentRound: Number(e.target.value)
        }
        setIsClassic(false); // regular user data
        dispatch(userActions.nextInLineUsersTable(apiConstants.NEXTINLINE, obj));
        setNumber(e.target.value);
    }

    const getClassicList = (e) => {
        e.preventDefault();
        const obj = {
            userCurrentRound: Number(e.target.value)
        }
        setIsClassic(true); // classic user data
        dispatch(userActions.nextInLineClassicUsersTable(apiConstants.NEXTINLINEFORCLASSIC, obj));
        setNumber(e.target.value);
    }

    const initialApiCall = () => {
        const obj = {
            userCurrentRound: 1
        }
        dispatch(userActions.nextInLineUsersTable(apiConstants.NEXTINLINE, obj));
    }

    useEffect(() => {
        initialApiCall();
    }, []);

    const displayedData = isClassic ? classicUsers : nextInLineData; // decide which data to show

    return (
        <>
            <div className="main-wrap">
                <Header changePlan={(e) => { planHandler(e) }} />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        <div className='dashboard-content p' style={{ paddingTop: "20px" }}>
                            <div className='card dash-card tag-block p-3 box_shadow '>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="row">
                                        <div className="col-lg-15" style={{ paddingLeft: "30px" }}>  
                                            <h2 className="heading-top">{siteConstants.NextInLine}</h2>  
                                        </div>
                                    </div>
                                </div>

                                <div className='row' style={{ paddingTop: "10px", paddingLeft: "30px", paddingBottom: "20px" }}>
                                    <div className='row'>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={1} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat1}</button>
                                        </div>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={2} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat2}</button>
                                        </div>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={3} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat3}</button>
                                        </div>
                                        <div style={{ paddingRight: "50px", paddingBottom: "20px" }}>
                                            <button type="button" value={4} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat4}</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={5} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat5}</button>
                                        </div>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={6} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat6}</button>
                                        </div>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={7} className="btn btn-primary" onClick={(e) => { getList(e); }}>{siteConstants.Mat7}</button>
                                        </div>
                                        <div style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                                            <button type="button" value={0} className="btn btn-primary" onClick={(e) => { getClassicList(e); setNumber("8") }}>{siteConstants.Mat8}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row' style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                    Total Members in this Matrix 0{number}: {displayedData?.length || 0}
                                </div>

                                <div className='row' style={{ padding: "20px" }}>
                                    <table className="table" >
                                        <thead style={{ paddingLeft: "30px" }}>
                                            <tr >
                                                <th scope="col" style={{ paddingLeft: "20px" }}>{siteConstants.Number}</th>
                                                <th scope="col">{siteConstants.UserName}</th>
                                                <th scope="col">{siteConstants.EmailId}</th>
                                                <th scope="col">{siteConstants.Name}</th>
                                            </tr>
                                        </thead>
                                        {
                                            displayedData?.length > 0 ? displayedData.map((user, index) => {
                                                return (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <th scope="row" style={{ paddingLeft: "30px" }}>{index + 1}</th>
                                                            <td>{user?.userDetail?.username}</td>
                                                            <td>{user?.userDetail?.email}</td>
                                                            <td>{user?.userDetail?.firstname + " " + user?.userDetail?.lastname}</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            }) : <tr><td colSpan="8">No Record Found</td></tr>
                                        }
                                    </table>
                                </div>
                            </div>
                            <div className='col-md-15 mt-3'>
                                <BannerRandomFooter />
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NextInLine;
